import React from 'react'
import ec from '../assets/imgs/ec.jpg'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
           
            <ul className="icons">
                <li><a href="#" className="icon alt fa-facebook"><span className="label">Facebook</span></a></li>                
                
            </ul>
            
            <ul className="copyright">
                <li> The European Commission support for the production of this publication does not
constitute an endorsement of the contents which reflects the views only of the
authors, and the Commission cannot be held responsible for any use which may
be made of the information contained therein.</li>
                <li>&copy; Asociatia Scoala de Antrenori IOAN KUNST GHERMANESCU </li><li>Design: <a href="https:/imaflux.com">Imaflux Design Studio</a></li>
            </ul>
        </div>
    </footer>
)

export default Footer
