import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import erasmus from '../assets/imgs/erasmus3.png'
import ec from '../assets/imgs/ec2.png'
import logo from '../assets/imgs/logo2.png'


import eng from '../assets/Flags/eng.png'
import es from '../assets/Flags/es.png'
import cat from '../assets/Flags/cat.png'
import it from '../assets/Flags/it.png'

const Header = (props) => (
    <header id="header" className="alt">
        
        <div id = "logos">
            <Link to="/ind" ><img className="one" src= {erasmus} /></Link>
            {/* <Link to="/" className="two"><img className="two"src = {logo}/></Link> */}
            
        </div>
        <ul style = {{zIndex: "1", paddingTop: "20px", marginBottom: "10px", textAlign: "center",width: "50%", margin: "auto"}}className="features"> 
                        <Link style={{ borderBottom: "none", paddingRight: "10px"}} to="/ind" ><img style ={{width: "10%"}}src={eng}></img></Link>

                        <Link style={{borderBottom: "none",paddingRight: "10px"}} to="/CAT/ind" ><img style ={{width: "10%"}}src={cat}></img></Link>
                        <Link style={{borderBottom: "none",paddingRight: "10px"}}to="/IT/ind" ><img style ={{width: "10%"}}src={it}></img></Link>
                        <Link style={{borderBottom: "none",paddingRight: "10px"}}to="/ES/ind" ><img style ={{width: "10%"}}src={es}></img></Link>

                    </ul>
        <nav id = "logos">
            <Link to="/ind"><img  className="three" src = {ec}/></Link>
            <a className="menu-link" onClick={props.onToggleMenu} href="javascript:;">Menu</a>
        </nav>
        
    </header>
)

Header.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Header
